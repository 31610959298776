import { v4 as uuidv4 } from 'uuid'

import * as SentryVue from '@sentry/vue'
import { AppLauncher } from '@capacitor/app-launcher'
import { get, isNil } from 'lodash'

import { useRouter } from 'vue-router'
import { Dialog } from '@capacitor/dialog'

import { modalController } from '@ionic/vue'
import { Browser } from '@capacitor/browser'
import linkType from '@/constants/linkType'
import api from '@/services/api'
import { analytics } from '@/services/analytics'
import { useAuthHelpers } from '@/composables/authHelpers'
import { events } from '@/composables/events'
import ModuleComponents from '@/components/ModuleComponents.vue'

const { openUrl } = useHelpers()
const authHelpers = useAuthHelpers()

export function useModuleHelpers() {
    const router = useRouter()
    const appStore = useAppStore()
    const moduleStore = useModuleStore()
    const badgeStore = useBadgeStore()
    const deviceStore = useDeviceStore()
    const { snackbar } = useSnackbars()

    function navigateBackToFirstModule(openModuleCount = null) {
        // TODO: Look into using vue memory router so that we have more control and can easily clear the stack etc.
        const stackLength = openModuleCount ?? moduleStore.stack.length

        if (stackLength === 1) {
            // Reload the module, calling router.go(0) will result in a page reload
            const moduleId = first(moduleStore.stack)
            router.replace({ name: 'view', params: { id: moduleId } })
        } else if (stackLength > 1) {
            // Navigate back
            const delta = (stackLength - 1) * -1
            router.go(delta)
        }

        moduleStore.loadModule()
    }

    function navigateBackToRoot(openModuleCount) {
        // TODO: Look into using vue memory router so that we have more control and can easily clear the stack etc.
        const stackLength = openModuleCount ?? moduleStore.stack.length

        // Avoid calling router.go(0) which results in a page reload
        if (!stackLength) {
            return
        }

        router.go(stackLength * -1)
    }

    function handleActions(actions) {
        return reduce(actions, (promise, action) => {
            return promise.then((result) => {
                // Handle the action
                // NOTE: can return promises here
                switch (action.action_type) {
                    case 'snackbar':
                        return snackbar({
                            message: action.message,
                            type: action.type,
                            duration: action.duration,
                        })
                    case 'back':
                        return router.go(action.times * -1)
                    case 'browser':
                        return new Promise((resolve) => {
                            const finished = async () => {
                                await Browser.removeAllListeners()
                                resolve()
                            }
                            Browser.addListener('browserFinished', finished)
                            if (action.close_on_load) {
                                Browser.addListener('browserPageLoaded', () => {
                                    setTimeout(() => {
                                        Browser.close()
                                        finished()
                                    }, action.close_delay_ms || 0)
                                })
                            }
                            Browser.open({ url: action.url })
                        })
                    case 'close-module':
                        navigateBackToRoot()
                        return
                    case 'mock-data-enable':
                        return appStore.enableMockData()
                    case 'mock-data-disable':
                        return appStore.disableMockData()
                    case 'refresh-badges':
                        return badgeStore.refreshAll()
                    case 'open-link':
                        // eslint-disable-next-line no-use-before-define
                        return openModuleOrOpenBrowserForLinkData(action.link)
                    case 'pause':
                        return new Promise((resolve) => {
                            setTimeout(resolve, action.duration || 0)
                        })
                    case 'refresh':
                        return moduleStore.loadModule()
                    case 'refresh-parent':
                        return moduleStore.refreshParent()
                    case 'replace-component':
                        return moduleStore.replaceComponent(action.component)
                    case 'reset':
                        return appStore.resetStorage()
                    case 'restart':
                        return appStore.restart()
                    default:
                        console.log('Unsupported action', action)
                        break
                }
            })
        }, Promise.resolve())
    }

    function openStore(link) {
        // Open the app store
        let storeUrl = link.url
        if (deviceStore.isIos) {
            storeUrl = `itms-apps://itunes.apple.com/app/${link.ios_app_id}`
        }
        if (deviceStore.isAndroid) {
            storeUrl = `market://details?id=${link.android_package}`
        }

        return AppLauncher.openUrl({
            url: storeUrl,
        })
    }

    async function getModuleOrOpenBrowserForLinkData(link) {
        if (!link || !link.type) {
            return
        }

        // link is external, just open url
        if (link.type === linkType.external) {
            openUrl(link)
            return
        }

        if (link.type === linkType.modal) {
            const modal = await modalController.create({
                component: ModuleComponents,
                componentProps: {
                    modules: [link.component],
                },
                initialBreakpoint: 1,
                breakpoints: [0, 1],
            })
            await modal.present()
            return
        }

        if (link.type === linkType.externalApp) {
            // TODO should we display something more friendly for app links?
            analytics.setScreenName({ screenName: link.url })

            // Handle opening external app on android
            if (deviceStore.isAndroid) {
                // NOTE: canOpenUrl ONLY works with package name on android
                const hasPackageInstalled = await AppLauncher.canOpenUrl({ url: link.android_package })

                // Open the store if the app isn't installed
                if (!hasPackageInstalled.value) {
                    return openStore(link)
                }

                // Try opening the app via schema
                const resultViaScheme = await AppLauncher.openUrl({ url: link.url })
                if (resultViaScheme.completed) {
                    return resultViaScheme
                }

                // Try opening the app via package name
                return AppLauncher.openUrl({ url: link.android_package })
            }

            // Handle opening external app on ios / web
            const canOpenUrl = await AppLauncher.canOpenUrl({ url: link.url })
            if (canOpenUrl.value) {
                return AppLauncher.openUrl({ url: link.url })
            }

            return openStore(link)
        }

        if (link.type === linkType.action) {
            return handleActions(link.actions)
        }

        if (link.type === linkType.apiAction) {
            return api.post(link.url, link.payload || [])
                .then(({ data }) => handleActions(data.actions))
                .catch((e) => {
                    if (authHelpers.isAuthenticationException(e)) {
                        return new Promise((resolve, reject) => {
                            authHelpers.handleAuthenticationException(
                                e.response.data,
                                router,
                                async ({ userSwitched } = { userSwitched: false }) => {
                                    // Remove the login view
                                    router.back()

                                    // Try again
                                    // eslint-disable-next-line no-use-before-define
                                    resolve(await getModuleOrOpenBrowserForLinkData(link))
                                },
                                () => {
                                    router.back()

                                    // User has aborted, no action needed
                                    reject()
                                },
                                true,
                            )
                        })
                    }
                    throw e
                })
        }

        const newModule = {
            id: uuidv4(),
        }

        if (link.type === linkType.component) {
            newModule.data = {
                ...link.component,
            }
        }

        if (link.type === linkType.apiComponent) {
            // set active module url
            newModule.link = {
                type: linkType.apiComponent,
                url: link.url,
            }
        }

        if (link.type === linkType.prompt) {
            const prompt = link.prompt
            return Dialog.confirm({
                title: prompt.title,
                message: prompt.message,
                okButtonTitle: prompt.confirm_text,
                cancelButtonTitle: prompt.cancel_text,
            })
                .then((confirm) => {
                    if (!confirm.value) {
                        // If not confirmed return a resolved promise
                        return Promise.resolve()
                    }
                    // eslint-disable-next-line no-use-before-define
                    return handleClick({
                        link: {
                            ...prompt.confirm_link,
                            // Include the payload, eg from form component
                            payload: link.payload,
                        },
                    })
                })
        }

        moduleStore.addModule(newModule)

        return Promise.resolve(newModule)
    }

    async function openModuleOrOpenBrowserForLinkData(link, replace = false) {
        const appModule = await getModuleOrOpenBrowserForLinkData(link)
        if (!appModule || !appModule.id) {
            return
        }

        const method = replace ? router.replace : router.push
        method({ name: 'view', params: { id: appModule.id } })
    }

    function handleClick(appModule, itemId, showLoadingOverlay = true) {
        // To support CardDetailData links we need to check read and read_url
        const read = get(appModule, 'data.read') || get(appModule, 'read')
        const readUrl = get(appModule, 'meta.read') || get(appModule, 'read_url')

        if (read !== true && readUrl && !isNil(itemId)) {
            moduleStore.markAsRead(readUrl, itemId)
                .then(() => {
                    appModule.data.read = true
                })
        }

        if (showLoadingOverlay && appModule.link?.type === 'api-action') {
            events.emit('toggleLoadingOverlay', true)
        }

        return openModuleOrOpenBrowserForLinkData(appModule.link)
            .finally(() => {
                if (showLoadingOverlay) {
                    events.emit('toggleLoadingOverlay', false)
                }
            })
    }

    function setAnalyticsScreenFromConfig(config) {
        const analyticsScreenName = get(config, 'meta.analytics_title')
        analytics.setScreenName({
            screenName: analyticsScreenName,
        })
        SentryVue.addBreadcrumb({
            category: 'navigation',
            level: 'info',
            data: {
                screenName: analyticsScreenName,
            },
        })
    }

    function isActionException(e) {
        return get(e, 'response.status') === 400
            && get(e, 'response.data.type') === 'App\\Exceptions\\ExceptionWithActions'
            && get(e, 'response.data.actions')
    }

    function handleActionException(data) {
        handleActions(data.actions)
    }

    function addIdsToModules(modules) {
        return map(modules, (module) => {
            // Add ids to children
            if (module.items) {
                module.items = addIdsToModules(module.items)
            }
            return {
                ...module,
                id: uuidv4(),
            }
        })
    }

    return {
        getModuleOrOpenBrowserForLinkData,
        openModuleOrOpenBrowserForLinkData,
        handleClick,
        handleActions,
        navigateBackToFirstModule,
        navigateBackToRoot,
        setAnalyticsScreenFromConfig,
        isActionException,
        handleActionException,
        addIdsToModules,
    }
}
