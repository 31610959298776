<template>
    <div v-html="html" ref="container" />
</template>

<script setup>
    import { onBeforeUnmount } from 'vue'

    const { openUrl } = useHelpers()

    const props = defineProps({
        html: String,
    })

    const container = ref(null)

    const listener = (e) => {
        if (!e.target) {
            return
        }

        // NOTE: Closest will return the element itself if it matches
        const el = e.target.closest('a[href]')
        if (!el) {
            return
        }

        // Prevent default browser behaviour
        e.stopPropagation()
        e.preventDefault()

        // Open the link in the in app browser
        const url = el.getAttribute('href')
        openUrl({ url })
    }

    onMounted(() => {
        container.value.addEventListener('click', listener)
    })

    onBeforeUnmount(() => {
        if (container.value) {
            container.value.removeEventListener('click', listener)
        }
    })
</script>
