<template>
    <template v-if="students">
        <template v-if="!onlyOneStudent">
            <div class="mt-4 mb-8">
                <DField label="Students" required required-color="text-red-600" id="students" :form-errors="errors">
                    <div
                        v-for="student in students"
                        :key="student.external_id"
                        class="w-full flex items-center p-4 border mb-3 rounded-lg transition-all"
                        :class="selectedStudents[student.external_id] ? 'border-[--color-600]' : 'border-gray-300'"
                        @click="toggleStudent(student)"
                    >
                        <UIAvatar :avatar="assign(student.avatar, { size: 'medium-small' })" />
                        <div class="mx-3">
                            <div class="text-md text-gray-900">
                                {{ student.avatar.description }}
                            </div>
                        </div>
                        <DCheckbox
                            v-model="selectedStudents[student.external_id]"
                            class="ml-auto pointer-events-none"
                        />
                    </div>
                </DField>
            </div>
        </template>
        <template v-else>
            <div class="w-full flex items-center pt-4 mb-8">
                <UIAvatar :avatar="assign(students[0].avatar, { size: 'small' })" />
                <div class="mx-3">
                    <div class="text-gray-900">
                        {{ students[0].avatar.description }}
                    </div>
                </div>
            </div>
        </template>
    </template>
</template>

<script setup>
    import { DField, DCheckbox } from '@digistorm/spark'
    import UIAvatar from '@/components/ui/UIAvatar.vue'

    const emit = defineEmits(['change'])

    const props = defineProps({
        students: Array,
        errors: Array,
    })

    const selectedStudents = ref({})

    const onlyOneStudent = computed(() => {
        return props.students.length === 1
    })

    const toggleStudent = (student) => {
        selectedStudents.value[student.external_id] = !selectedStudents.value[student.external_id]
    }

    const selectedStudentIds = computed(() => {
        return transform(selectedStudents.value, (selected, studentIsSelected, studentId) => {
            if (studentIsSelected) {
                selected.push(studentId)
            }
        }, [])
    })

    onMounted(() => {
        if (onlyOneStudent.value) {
            toggleStudent(props.students[0])
        }
    })

    watch(selectedStudentIds, (val) => {
        emit('change', val)
    }, {
        deep: true,
    })
</script>
