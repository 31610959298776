import { createApp } from 'vue'
import { createPinia, storeToRefs } from 'pinia'
import { createI18n } from 'vue-i18n'
import { IonicVue } from '@ionic/vue'
import { createRouter, createWebHistory } from '@ionic/vue-router'
import { includes, forIn } from 'lodash'
import InlineSvg from 'vue-inline-svg'
import VueVirtualScroller from 'vue-virtual-scroller'
import { SENTRY_XHR_DATA_KEY } from '@sentry-internal/browser-utils'

import { i18n as sparkI18n } from '@digistorm/spark'
import { i18n as formsI18n } from '@digistorm/forms'
import messages from '@intlify/unplugin-vue-i18n/messages'
import * as SentryVue from '@sentry/vue'

import dayjs from '@/dayjs'

import { routes } from './routes.js'

import '@ionic/vue/css/core.css'
import '@/tailwind.css'
import 'vue-virtual-scroller/dist/vue-virtual-scroller.css'

import * as AppModules from '@/components/modules'

import App from './App.vue'

import PhIcon from '@/components/app/PhIcon.vue'
import Empty from '@/components/ui/Empty.vue'

const app = createApp(App)

const pinia = createPinia()

const allMessages = merge(messages, sparkI18n, formsI18n)

const i18n = createI18n({
    legacy: false,
    locale: 'en-AU',
    fallbackLocale: 'en-AU',
    silentTranslationWarn: true,
    messages: allMessages,
})

app.config.globalProperties.$dayjs = dayjs

app.use(pinia)
app.use(IonicVue)
app.use(i18n)
app.use(VueVirtualScroller)

app.component('PhIcon', PhIcon)
app.component('Empty', Empty)
app.component('InlineSvg', InlineSvg)

forIn(AppModules, (component, name) => {
    app.component(name, component)
})

const appStore = useAppStore()
const { launched } = storeToRefs(appStore)

const router = createRouter({
    history: createWebHistory(),
    routes,
})

router.beforeEach((to, from, next) => {
    // If the app hasn't launched yet, navigate to the launch view
    if (to.name !== 'launch' && !launched.value && to.name !== 'maintenance') {
        return next({ name: 'launch' })
    }
    next()
})

const appVersion = import.meta.env.VITE_APP_VERSION ?? '0.0.1'

SentryVue.init(
    {
        app,
        dsn: import.meta.env.VITE_APP_SENTRY_DSN,
        environment: import.meta.env.VITE_APP_SENTRY_ENV ?? 'production',
        release: `school-app@${appVersion}`,
        dist: '1',
        integrations: [
            SentryVue.browserTracingIntegration({
                router,
            }),
            SentryVue.replayIntegration(),
        ],

        ignoreErrors: ['CanceledError'],

        // Set tracesSampleRate to 1.0 to capture 100%
        // of transactions for performance monitoring.
        // We recommend adjusting this value in production
        tracesSampleRate: import.meta.env.VITE_APP_SENTRY_VUE_TRACES_SAMPLE_RATE,

        // Capture Replay for 10% of all sessions,
        // plus for 100% of sessions with an error
        replaysSessionSampleRate: 0.1,
        replaysOnErrorSampleRate: 1.0,

        beforeBreadcrumb(breadcrumb, hint) {
            if (breadcrumb.category !== 'xhr' || !hint?.xhr) {
                return breadcrumb
            }

            // Sentry adds a custom object to the XHR object that contains the request headers
            // The headers are normalised to lowercase here
            const requestId = hint.xhr?.[SENTRY_XHR_DATA_KEY]?.request_headers?.['x-beyond-request-id']
            if (!requestId) {
                return breadcrumb
            }

            breadcrumb.data.beyond_request_id = requestId
            return breadcrumb
        },
    },
)

app.use(router)

app.mount('#app')
