<template>
    <div class="flex-1 flex flex-col items-center justify-center text-center py-20">
        <div class="text-[--color-600] mb-6">
            <slot name="icon">
                <InlineSvg src="/icons/error.svg" />
            </slot>
        </div>
        <p class="font-medium mb-2 text-gray-800 text-center">
            <slot name="title">
                Something went wrong
            </slot>
        </p>

        <p class="text-sm text-gray-700 text-center">
            <slot name="subtitle">
                Please try again later.
            </slot>
        </p>

        <div v-if="errorRequestRequestId" class="text-left w-full mt-10">
            <div class="text-sm font-medium mb-2 ml-2">Error details</div>
            <div class="radius-card-sm surface-extra-subtle p-3 text-neutral-subtle text-sm">
                <div class="space-y-3">
                    <div>
                        <div class="font-medium flex items-center">Request ID</div>
                        <div>{{ errorRequestRequestId }}</div>
                    </div>
                    <div v-if="errorRequestedAt">
                        <div class="font-medium flex items-center">Request date and time</div>
                        <div>{{ formattedRequestedAt }}</div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
    import InlineSvg from 'vue-inline-svg'
    import dayjs from '@/dayjs'

    const props = defineProps({
        axiosError: Object,
        requestId: String,
        requestedAt: String,
    })

    const errorRequestRequestId = computed(() => {
        return props.axiosError
            ? props.axiosError.config?.headers?.['X-BEYOND-REQUEST-ID']
            : props.requestId
    })

    const errorRequestedAt = computed(() => {
        return props.axiosError
            ? props.axiosError.config?.headers?.['X-BEYOND-REQUESTED-AT']
            : props.requestedAt
    })

    const formattedRequestedAt = computed(() => {
        return `${dayjs(errorRequestedAt.value).format('YYYY-MM-DD HH:mm:ss')}Z`
    })
</script>
