import {
    upperFirst,
    camelCase,
    transform,
    startsWith,
} from 'lodash'
import { Browser } from '@capacitor/browser'

import { analytics } from '@/services/analytics'

export function useHelpers() {
    const pascalCase = (string) => {
        return upperFirst(camelCase(string))
    }

    const validateColor = (color, defaultColor = 'transparent') => {
        if (/^#([0-9A-F]{3}){1,2}$/i.test(color)) {
            return color
        }

        // TODO: maybe do something else if its not a hex
        return color === '#' ? defaultColor : color
    }

    const errorsForPath = (errors, searchPath) => {
        return transform(errors, (output, itemErrors, itemPath) => {
            if (!startsWith(itemPath, searchPath)) {
                return
            }
            output[itemPath.replace(searchPath, '')] = itemErrors
        }, {})
    }

    const positionStyles = (position) => {
        switch (position) {
            case 'fill':
                return {
                    position: 'absolute',
                    left: '0',
                    top: '0',
                    right: '0',
                    bottom: '0',
                }
            case 'top-left':
                return {
                    position: 'absolute',
                    left: '0%',
                    top: '0%',
                }
            case 'top-center':
                return {
                    position: 'absolute',
                    left: '50%',
                    top: '0%',
                    transform: 'translate(-50%, 0%)',
                }
            case 'top-right':
                return {
                    position: 'absolute',
                    right: '0%',
                    top: '0%',
                }

            case 'center-left':
                return {
                    position: 'absolute',
                    left: '0%',
                    top: '50%',
                    transform: 'translate(0%, -50%)',
                }
            case 'center':
                return {
                    position: 'absolute',
                    left: '50%',
                    top: '50%',
                    transform: 'translate(-50%, -50%)',
                }
            case 'center-right':
                return {
                    position: 'absolute',
                    right: '0%',
                    top: '50%',
                    transform: 'translate(0%, -50%)',
                }
            default:
                return {}
        }
    }

    const shouldOpenUrlOutsideApp = (url) => {
        let parsedUrl

        // Invalid URLs will throw a TypeError
        try {
            parsedUrl = new URL(url)
        } catch (e) {
            return false
        }

        const { hostname, protocol, pathname } = parsedUrl

        return ['mailto:', 'tel:', 'sms:'].includes(protocol)
    }

    const openUrl = (link) => {
        const url = link.url
        analytics.setScreenName({ screenName: url })

        if (shouldOpenUrlOutsideApp(url) || link.open_externally) {
            window.location.href = url
            return Promise.resolve()
        }

        Browser.open({ url })
    }

    return {
        pascalCase,
        validateColor,
        errorsForPath,
        positionStyles,
        shouldOpenUrlOutsideApp,
        openUrl,
    }
}
